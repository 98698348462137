import React, { Component } from "react";
// Import React Table
import "./AstakVarga.css";
import Table from "react-bootstrap/Table";
import { withTranslation } from "react-i18next";

// const getColumnWidth = (row, accessor, headerText) => {
//   const maxWidth = 400;
//   const magicSpacing = 5;
//   const cellLength = Math.max(row[accessor] || "".length, headerText.length);
//   console.log(Math.min(maxWidth, cellLength * magicSpacing));
//   return Math.min(maxWidth, cellLength * magicSpacing);
// };

let vargaMapper = {
  Prasthar: "PrastharashtakVarga",
  Sarvashtak: "SarvashtakVarga",
  Bhinnashtak: "BhinnashtakVarga",
};

function extract_image_link(name) {
  let imglink = "/assets/Planets/";
  return imglink + name + ".png";
}
class RelationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  render() {
    const { data } = this.props;
    const { t, i18n } = this.props;
    var page = 0;
    if (this.props.tab === "Shodya") {
      page = 3;
    } else {
      page = 9;
    }
    return (
      <>
        {/* <ReactTable
          style={{
            margin: "25px 0 ",
            fontSize: "1em",
            maxWidth: this.props.tab === "Shodya" ? "850px" : "1000px",
          }}
          data={data}
          showPagination={false}
          columns={this.props.tab === "Shodya" ? Shodya : Samudaya}
          pageSize={page}
        /> */}
        {this.props.sarvValue === "4" &&
        this.props.relation === "Sarvashtak" ? (
          <div className="shodya">
            <Table bordered responsive="xs" className="content-table">
              <thead>
                <tr role="row" className="header-table">
                  <th colSpan="13" role="columnheader">
                    {t("Shodya Pinda")}
                  </th>
                </tr>
                <tr>
                  <th>{t("Header")}</th>
                  <th>{t("Sun")}</th>
                  <th>{t("Moon")}</th>
                  <th>{t("Mars")}</th>
                  <th>{t("Mercury")}</th>
                  <th>{t("Jupiter")}</th>
                  <th>{t("Venus")}</th>
                  <th>{t("Saturn")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map(function (val, i) {
                  return (
                    <tr key={val + i}>
                      <td>{t(val["Header"])}</td>
                      <td>{t(val["Sun"])}</td>
                      <td>{t(val["Moon"])}</td>
                      <td>{t(val["Mars"])}</td>
                      <td>{t(val["Mercury"])}</td>
                      <td>{t(val["Jupiter"])}</td>
                      <td>{t(val["Venus"])}</td>
                      <td>{t(val["Saturn"])}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="astak">
            <Table
              bordered
              responsive="xs"
              className={` ${
                this.props.typeBinValue === "3" &&
                this.props.relation === "Bhinnashtak"
                  ? "content-table-net Bhinnashtak"
                  : this.props.relation === "Prasthar"
                  ? "content-table prasthar"
                  : this.props.relation === "Sarvashtak"
                  ? "content-table sarvashtak"
                  : "content-table"
              }`}
            >
              <thead>
                <tr role="row" className="header-table">
                  <th colSpan="13" role="columnheader">
                    {this.props.relation === "Prasthar"
                      ? t(
                          `${vargaMapper[this.props.relation]} - ${
                            this.props.selectedPlanetpr
                          } - ${this.props.selectedTypepr}`
                        )
                      : this.props.relation === "Sarvashtak"
                      ? t(
                          `${vargaMapper[this.props.relation]} - ${
                            this.props.selectedModesarv
                          }`
                        )
                      : t(`BhinnashtakVarga - ${this.props.selectedTypebin}`)}
                  </th>
                </tr>
                <tr>
                  <th>{t("Main Planet")}</th>
                  <th>{t("Ari")}</th>
                  <th>{t("Tau")}</th>
                  <th>{t("Gem")}</th>
                  <th>{t("Can")}</th>
                  <th>{" " + t("Leo") + " "}</th>
                  <th>{t("Vir")}</th>
                  <th>{t("Lib")}</th>
                  <th>{t("Sco")}</th>
                  <th>{t("Sag")}</th>
                  <th>{t("Cap")}</th>
                  <th>{t("Aqu")}</th>
                  <th>{t("Pis")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map(function (val, i) {
                  return (
                    <>
                     <tr key={val + i} className={i === 8 ? "total-row" : ""}>
                      <td style={{ textAlign: "left" }}>
                        {val["Main Planet"] !== "Ascendant" ||
                        val["Main Planet"] !== "Total" ? (
                          <img
                            className="planet-images"
                            alt={
                              val["Main Planet"]
                                ? val["Main Planet"].slice(0, 3)
                                : ""
                            }
                            src={extract_image_link(val["Main Planet"])}
                          ></img>
                        ) : val["Main Planet"] === "Asc" ? (
                          ""
                        ) : (
                          <></>
                        )}{" "}
                        {t(val["Main Planet"])}
                      </td>
                      <td>{t(val["Ari"])}</td>
                      <td>{t(val["Tau"])}</td>
                      <td>{t(val["Gem"])}</td>
                      <td>{t(val["Can"])}</td>
                      <td>{t(val["Leo"])}</td>
                      <td>{t(val["Vir"])}</td>
                      <td>{t(val["Lib"])}</td>
                      <td>{t(val["Sco"])}</td>
                      <td>{t(val["Sag"])}</td>
                      <td>{t(val["Cap"])}</td>
                      <td>{t(val["Aqu"])}</td>
                      <td>{t(val["Pis"])}</td>
                    </tr>
                    </>
                   
                    
                  );
                })}
                {/* Grand Total row with validation */}
  {data && 
   data.length > 0 && 
   typeof data[data.length - 1]["Total"] === 'number' &&
   !isNaN(data[data.length - 1]["Total"]) && 
   isFinite(data[data.length - 1]["Total"]) && (
    <tr className="bg-gray-50">
      <td className="p-2 font-bold"><b>{t("Grand Total")}</b></td>
      <td colSpan="11"></td>
      <td className="p-2 text-center font-bold">
      <b>  {t(data[data.length - 1]["Total"])}</b>
      </td>
    </tr>
  )}
              </tbody>
            </Table>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(RelationTable);
